<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['skinRoughness'])">
      <single-answer-question
        title="label.skinRoughness"
        :value="skinRoughness"
        :options="skinRoughnessOptions"
        dysfunction="skin-texture"
        @input="onFieldChange('skinRoughness', $event)"
      />
    </question-with-error-wrap>

    <question-divider
      class="q-mt24 q-mb32 q-mt32-md q-mb40-md"
      title="questionnaire.tellUsMore.title"
    />

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['exfoliateSkin'])">
      <single-answer-question
        id="exfoliate-skin"
        title="label.exfoliateSkin"
        :value="exfoliateSkin"
        :options="$options.yesNoResponseOptions"
        dysfunction="skin-texture"
        yes-no
        @input="onFieldChange('exfoliateSkin', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['chemicalPeeling'])">
      <single-answer-question
        id="chemical-peeling"
        title="label.chemicalPeeling"
        :value="chemicalPeeling"
        :options="$options.yesNoResponseOptions"
        dysfunction="skin-texture"
        yes-no
        @input="onFieldChange('chemicalPeeling', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionDivider from '@/modules/questionnaire/components/common/QuestionDivider';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { flagAiSelectedOption } from '@/modules/questionnaire/api/helpers';
import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import {
  YES_NO_RESPONSE_OPTIONS,
  AMOUNT_OPTIONS
} from '@/modules/questionnaire/constants/questions';

export default {
  name: 'RoughnessTemplate',
  components: { QuestionDivider, QuestionWithErrorWrap, SingleAnswerQuestion },
  mixins: [stepTemplateMixin],
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    skinRoughness: {
      type: String,
      required: true
    },
    skinRoughnessAiSelected: {
      type: String,
      default: ''
    },
    exfoliateSkin: {
      type: String,
      required: true
    },
    chemicalPeeling: {
      type: String,
      required: true
    }
  },
  computed: {
    skinRoughnessOptions() {
      return flagAiSelectedOption(AMOUNT_OPTIONS, this.skinRoughnessAiSelected);
    }
  }
};
</script>
