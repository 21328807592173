import RoughnessTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-texture/roughness/RoughnessTemplate';

import { AMOUNT, BOOLEAN } from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/SkinTexture/Roughness/RoughnessTemplate',
  component: RoughnessTemplate
};

const createStory = props => () => ({
  components: { RoughnessTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><roughness-template v-bind="$options.storyProps" /></div>'
});

export const required = createStory({
  skinRoughness: AMOUNT.CONSIDERABLY,
  exfoliateSkin: BOOLEAN.YES,
  chemicalPeeling: BOOLEAN.NO
});

export const aiSelected = createStory({
  skinRoughness: AMOUNT.CONSIDERABLY,
  skinRoughnessAiSelected: AMOUNT.CONSIDERABLY,
  exfoliateSkin: BOOLEAN.YES,
  chemicalPeeling: BOOLEAN.NO
});
